import logo from './logo.svg';
import telegramlogo from './telegramlogo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
        <div style={{ backgroundColor: "rgb(252, 227, 138)", minHeight: "100vh" }}>

              <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
               

               <p class="bold-large-text">Coming soon...</p>
               <p />
               
<a 
  href="https://t.me/FinKaze_Bot" 
  target="_blank" 
  rel="noopener noreferrer" 
  style={{ 
    textDecoration: 'none', 
    color: 'black', 
    display: 'inline-flex', 
    alignItems: 'center', 
    gap: '5px', 
    fontSize: '1.5rem' 
  }}
>
  <img 
    src={telegramlogo} 
    className="Telegram-logo" 
    alt="logo" 
    style={{
      width: '2rem', 
      height: '2rem'
    }}
  />
  Join our Telegram channel
</a>



               <a 
  href="mailto:hello@finkaze.com" 
  className="large-black-email" 
  style={{
    position: 'fixed',
    bottom: '20px',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '1rem',
    color: 'black',
    textDecoration: 'none'
  }}
>
  hello@finkaze.com
</a>


              </header>
      
        </div>
    </div>
  );
}

export default App;
